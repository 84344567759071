import {Card, CardContent, Chip, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import "../styles/operation.css";
import {getSwappTransactions} from "../store/slices/transactionSlice";
import {unwrapResult} from "@reduxjs/toolkit"; // Tell webpack that Button.js uses these styles

function SwappTransactionsModal({open, onClose}) {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);

    function returnStatus(status) {
        switch (status) {
            case 0:
                return <Chip label="Ожидает оплаты" color="info"/>;
            case 1:
                return <Chip label="Оплата получена" color="success"/>;
            case 2:
                return <Chip label="Операция завершена" color="info"/>;
            case 3:
                return <Chip label="Оплата просрочена" color="error"/>;
            case 4:
                return <Chip label="Отмена" color="error"/>;
            case 5:
                return <Chip label="Отмена" color="error"/>;
            default:
                return <Chip label="Неизвестно"/>;
        }
    }

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 300,
        },
        {
            field: "status",
            headerName: "Статус",
            width: 300,
            renderCell: (params) => returnStatus(params.value),
        },
        {
            field: "amount_pay",
            headerName: "Сумма",
            width: 150,
            valueGetter: (params) => {
                return params.value.toFixed(2);
            },
        },
    ];

    const [tableHeight, setTableHeight] = React.useState(
        window.innerHeight - 200
    );
    const dispatch = useDispatch();
    const swappTransactions = useSelector((state) => state.transaction.swappTransactions);

    const resize = async () => {
        console.log(window.innerHeight);
        setTableHeight(window.innerHeight - 200);
    };

    React.useEffect(() => {
        dispatch(
            getSwappTransactions({page: 1})
        )
            .then(unwrapResult)
            .then((result) => {
                console.log('result', result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });

        resize();
        window.addEventListener("resize", resize);
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);


    function handlePageChange(page) {
        setPage(page + 1);
        dispatch(
            getSwappTransactions({
                page: page + 1,
            })
        )
            .then(unwrapResult)
            .then((result) => {
                console.log(result); // => 233
                setRows(result.data.data);
            })
            .catch((error) => {
                console.error(error); // if there is an error
            });
    }


    return (
        <Dialog
            maxWidth="xl"
            fullWidth={true}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Транзакции CryptoSwapp
            </DialogTitle>
            <DialogContent>

                <div>
                    <Card sx={{minWidth: 275}}>
                        <CardContent className="text-start p-3">
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div style={{height: tableHeight, width: "100%"}}>
                                        <DataGridPremium
                                            getRowClassName={(params) => `color-swapp-${params.row.status}`}
                                            rows={rows}
                                            rowsPerPageOptions={[10]}
                                            paginationMode={'server'}
                                            disableColumnFilter={true}
                                            pagination
                                            onPageChange={handlePageChange}
                                            rowCount={swappTransactions.meta?.total}
                                            columns={columns}
                                            components={{Toolbar: CustomToolbar}}
                                            componentsProps={{
                                                toolbar: {
                                                    filename: "Транзакции CryptoSwapp",
                                                    showQuickFilter: true,
                                                    quickFilterProps: {debounceMs: 500},

                                                },
                                            }}
                                            initialState={{
                                                sorting: {
                                                    sortModel: [
                                                        {
                                                            field: "created_at",
                                                            sort: "desc",
                                                        },
                                                    ],
                                                },
                                            }}
                                            localeText={localeGrid}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SwappTransactionsModal;
